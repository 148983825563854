<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Webhosting Small: goedkope shared hosting</h1>
        <Price :item="data.staticData.prices.webhostingPrices[0]"/>
        <p><i>“Webhosting Small is voor mij het ideale pakket. Een scherpe prijs per jaar en toch professionele mogelijkheden om optimaal gebruik te kunnen maken van mijn website,”</i> aldus een tevreden klant met dit goedkope pakket voor webhosting.</p>
        <p>Webhosting Small is goed geschikt voor kleine websites met één of meerdere databases. Dankzij 3GB webruimte kunt u er voldoende websitebestanden op kwijt, zolang er geen sprake is van een grote collectie foto’s of video’s. Met 20 e-mailadressen kunt u een aantal verschillende aanmaken en dankzij 15GB dataverkeer per maand kunt u een behoorlijke hoeveelheid bezoekers verwerken.</p>
        <h2>Domein hosting in Nederland</h2>
        <p>We zorgen met Webhosting Small voor goedkope domein hosting in Nederland. Vanuit ons Nederlandse datacenter zorgt u er met dit pakket eenvoudig voor dat u hosting bij uw domein kunt afnemen, zodat u daarop een website kunt bouwen.</p>
        <h3>Uw voordelen bij Webhosting Small</h3>
        <ul>
          <li>Goedkope domein hosting in Nederland</li>
          <li>Met virusscanner en spamfilter</li>
          <li>Subdomeinen, databases en voldoende dataverkeer</li>
        </ul>
        <p>Bekijk naast het pakket Webhosting Small ook de meer uitgebreide pakketten. vergelijk dit pakket bijvoorbeeld met Webhosting Large en Webhosting Extra Large. U kunt op die manier een goede keuze maken uit de verschillende pakketten die er beschikbaar zijn.</p>
        <img
          class="framed-image"
          src="../../assets/images/webhosting.png"
          height="179"
          width="300"
          title="domein hosting"
          alt="domein hosting"
        >
        <h2>Domeinnaam en hosting voor de laagste prijs</h2>
        <p>Met Webhosting Small kiest u eenvoudig voor goedkope hosting bij een domeinnaam. Het is webhosting voor de laagste prijs, waarmee u uiteraard kunt rekenen op onze maximale betrouwbaarheid, goede beveiliging en maximale privacy. We gebruiker servers in Nederland, waar u al voor een paar tientjes per jaar gebruik van kunt maken.</p>
        <p>Op die manier kunt u optimaal gebruik maken van de professionele mogelijkheden voor webhosting, zonder dat dit veel hoeft te kosten. In plaats daarvan betaalt u een scherpe prijs per jaar voor Webhosting Small. Beheer uw eigen website met voldoende dataverkeer en webruimte voor een kleine tot gemiddelde website.</p>
        <RandomQuote/>
      </div>
      <WebhostingRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import WebhostingRightSideBlocks from '@/components/WebhostingRightSideBlocks.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'WebhostingSmall',
  components: {
    Breadcrumbs,
    Price,
    WebhostingRightSideBlocks,
    RandomQuote,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>